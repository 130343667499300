// Modified from here
// https://codepen.io/fabiowallner/pen/YOyJbJ

import react from "react";
import styles from './rotate.module.css';

export default function RotateToPortrait({ customText }) {

    return (
        <div className={styles.body}>
            <div className={styles.phone}>
            <img style={{ marginLeft: '40px', marginTop:'10px', height: '20px', width: '40px', transform: 'rotate(90deg)' }} src={require('/public/images/inStryde_Primary_Isolated_Mark_Full_Colo.png')} />
            </div>
            <div style={{fontSize: "1.3em"}} className={styles.message}>
                Please rotate your device!
            </div>
        </div>

    )
}