import { useEffect, useState, useRef } from 'react'
import '../styles/global.css'
import { Helmet } from 'react-helmet'
import Script from 'next/script'

import RotateToPortrait from '../components/rotate-device/rotate'
import { isMobile } from 'react-device-detect'
import Head from 'next/head'
//import TagManager from 'react-gtm-module'

export default function App({ Component, pageProps }) {
   const previousWidth = useRef(null)
   const [orientationPortrait, setorientationPortrait] = useState(true)
   const [height, setHeight] = useState(null)
   const [androidKeyboardUp, setAndroidKeyboardUp] = useState(false)
   const [beginRun, setBeginRun] = useState(false)

   const checkOrientation = () => {
      if (innerHeight < innerWidth) {
         console.log('landscape')
         setorientationPortrait(false)
      } else {
         console.log('portrait')
         setorientationPortrait(true)
      }
   }

   useEffect(() => {
      //TagManager.initialize({ gtmId: 'GTM-NF3GPHB' })
      if (isMobile) {
         setHeight(innerHeight)
         setBeginRun(true)
         previousWidth.current = innerWidth
         // checkOrientation()

         // // Listen for resize changes
         // window.addEventListener("resize", function() {
         //     console.log("innerHeight: " + innerHeight);
         //     console.log("innerWidth: " + innerWidth);
         //     // Get screen size (inner/outerWidth, inner/outerHeight)
         //     checkOrientation()

         // }, false);

         var mql = window.matchMedia('(orientation: landscape)')

         // Add a media query change listener
         mql.addListener(function (m) {
            if (m.matches) {
               console.log('landscape')
               setorientationPortrait(false)
            } else {
               console.log('portrait')
               setorientationPortrait(true)
            }
         })

         // Android soft keyboard code to make sure text input box is visible on devices with a small screen
         // Currently causes next button to go off screen on quiz due to requiring a div to implement the styling compared to a react fragment

         // window.addEventListener('resize', function(event) {
         //     console.log(innerWidth, previousWidth.current)
         //     console.log(!mql.matches, orientationPortrait)
         //     if (!mql.matches && orientationPortrait && innerWidth == previousWidth.current) {
         //         console.log("keyboard Fired")
         //         var ua = navigator.userAgent.toLowerCase();
         //         var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
         //         if(isAndroid) {
         //             setAndroidKeyboardUp((v) => (!v))
         //         }
         //     }
         //     previousWidth.current = innerWidth
         //     console.log("RESIZE FIRED")
         // }, true);
      }
   }, [])

   function FaceBookPixel() {
      // useEffect(() => {
      //    if (typeof window !== 'undefined') {
      //       import('react-facebook-pixel')
      //          .then((x) => x.default)
      //          .then((ReactPixel) => {
      //             ReactPixel.init('273224277500639')
      //             ReactPixel.pageView()
      //          })
      //    }
      // })
      // return null
   }

   if (beginRun) {
      if (orientationPortrait) {
         return (
            // <div style={{position: "relative", top: `${androidKeyboardUp ? '-20vh' : '0vh'}`}}>
            <>
               {/* <Helmet bodyAttributes={{style: 'background-color : #fff'}}/> */}
               <meta
                  name="viewport"
                  content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
               />
               <Head>
                  <meta name="facebook-domain-verification" content="yiesfimjuqvnmi73k5s7ly2lahujmz" />
               </Head>
               <Script
                  src="https://www.googleoptimize.com/optimize.js?id=OPT-MC2WKQ5"
                  strategy="beforeInteractive"
               ></Script>
               <Script
                  src="https://www.googletagmanager.com/gtag/js?id=G-Q9H3ZGWG0F"
                  strategy="afterInteractive"
               ></Script>
               <Script id="gtag-base" strategy="afterInteractive">
                  {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  
                  gtag('config', 'AW-10855922930');
                  gtag('config', 'G-Q9H3ZGWG0F', {
                     page_path: window.location.pathname,
                   });
               `}
               </Script>
               <Script>
                  {`
                     !function(f,b,e,v,n,t,s)
                     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                     n.queue=[];t=b.createElement(e);t.async=!0;
                     t.src=v;s=b.getElementsByTagName(e)[0];
                     s.parentNode.insertBefore(t,s)}(window, document,'script',
                     'https://connect.facebook.net/en_US/fbevents.js');
                     fbq('init', '273224277500639');
                     fbq('track', 'PageView');
                  `}
               </Script>
               {/* <!-- Hotjar Tracking Code for https://app.instryde.com --> */}
               <Script>
                  {`(function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3188656,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `}
               </Script>
               {isMobile ? (
                  <Helmet>
                     <meta
                        name="viewport"
                        content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
                     />
                  </Helmet>
               ) : null}
               <title>SMARTFIT by InStryde</title>
               <Component {...pageProps} />
            </>
            // </div>
         )
      } else {
         return (
            <>
               <Helmet>
                  {/* <meta
                     name="viewport"
                     content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
                  /> */}
               </Helmet>
               <RotateToPortrait />
            </>
         )
      }
   } else {
      return (
         <>
            <title>SMARTFIT by InStryde</title>
            <Head>
               <meta name="facebook-domain-verification" content="yiesfimjuqvnmi73k5s7ly2lahujmz" />
            </Head>
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-Q9H3ZGWG0F" strategy="afterInteractive"></Script>
            <Script id="gtag-base" strategy="afterInteractive">
               {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  
                  gtag('config', 'AW-10855922930');
                  gtag('config', 'G-Q9H3ZGWG0F', {
                     page_path: window.location.pathname,
                   });
               `}
            </Script>
            <Script
               src="https://www.googleoptimize.com/optimize.js?id=OPT-MC2WKQ5"
               strategy="beforeInteractive"
            ></Script>
            <Script>
               {`(function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3188656,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `}
            </Script>
            <Script>
               {`
                     !function(f,b,e,v,n,t,s)
                     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                     n.queue=[];t=b.createElement(e);t.async=!0;
                     t.src=v;s=b.getElementsByTagName(e)[0];
                     s.parentNode.insertBefore(t,s)}(window, document,'script',
                     'https://connect.facebook.net/en_US/fbevents.js');
                     fbq('init', '273224277500639');
                     fbq('track', 'PageView');
                  `}
            </Script>
            {/* <!-- Meta Pixel Code for https://app.instryde.com --> */}
            <Helmet>
               {/* <meta
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                  name="viewport"
                  data-react-helmet="true"
               /> */}
            </Helmet>
            <Component {...pageProps} />
         </>
      )
   }
}
